import { useState } from 'react'

import { ActionType, ExpiryInterval } from 'src/types'

import client from '../client'

export const useSendCommentAuthorAction = () => {
  const [loading, setLoading] = useState(false)

  const _calculate_expiry = (expiryInterval: ExpiryInterval): string | null => {
    const now = new Date()
    const expiry = new Date(now)

    switch (expiryInterval) {
      case ExpiryInterval.ONE_HOUR:
        expiry.setHours(now.getHours() + 1)
        break
      case ExpiryInterval.TWO_HOURS:
        expiry.setHours(now.getHours() + 2)
        break
      case ExpiryInterval.FOUR_HOURS:
        expiry.setHours(now.getHours() + 4)
        break
      case ExpiryInterval.SIX_HOURS:
        expiry.setHours(now.getHours() + 6)
        break
      case ExpiryInterval.TWELVE_HOURS:
        expiry.setHours(now.getHours() + 12)
        break
      case ExpiryInterval.TWENTY_FOUR_HOURS:
        expiry.setHours(now.getHours() + 24)
        break
      case ExpiryInterval.SEVEN_DAYS:
        expiry.setDate(now.getDate() + 7)
        break
      case ExpiryInterval.FOURTEEN_DAYS:
        expiry.setDate(now.getDate() + 14)
        break
      case ExpiryInterval.TWENTY_EIGHT_DAYS:
        expiry.setDate(now.getDate() + 28)
        break
    }

    if (expiryInterval === ExpiryInterval.INDEFINITELY) return null

    return expiry.toISOString()
  }

  const send = async (
    trackedUserId: number,
    commentAuthor: string,
    commentAuthorId: string,
    actionType: string = ActionType.MUTE,
    expiryInterval?: ExpiryInterval
  ): Promise<boolean> => {
    let response
    setLoading(true)

    try {
      response = await client.post('/platforms/users/comment-authors/action', {
        tracked_user_id: trackedUserId,
        platform_id: commentAuthorId,
        platform_username: commentAuthor,
        action_type: actionType,
        expires_at: expiryInterval ? _calculate_expiry(expiryInterval) : null,
      })
    } catch (error: any) {
      // console.error(error)
    } finally {
      setLoading(false)
      return response?.status === 204
    }
  }

  return { loading, send }
}
