export enum ExpiryInterval {
  ONE_HOUR = 'ONE_HOUR',
  TWO_HOURS = 'TWO_HOURS',
  FOUR_HOURS = 'FOUR_HOURS',
  SIX_HOURS = 'SIX_HOURS',
  TWELVE_HOURS = 'TWELVE_HOURS',
  TWENTY_FOUR_HOURS = 'TWENTY_FOUR_HOURS',
  SEVEN_DAYS = 'SEVEN_DAYS',
  FOURTEEN_DAYS = 'FOURTEEN_DAYS',
  TWENTY_EIGHT_DAYS = 'TWENTY_EIGHT_DAYS',
  INDEFINITELY = 'INDEFINITELY',
}

export const ExpiryIntervalLabels: Record<string, string> = {
  ONE_HOUR: '1 Hour',
  TWO_HOURS: '2 Hours',
  FOUR_HOURS: '4 Hours',
  SIX_HOURS: '6 Hours',
  TWELVE_HOURS: '12 Hours',
  TWENTY_FOUR_HOURS: '24 Hours',
  SEVEN_DAYS: '7 Days',
  FOURTEEN_DAYS: '14 Days',
  TWENTY_EIGHT_DAYS: '28 Days',
  INDEFINITELY: 'Indefinitely',
}
