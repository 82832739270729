import * as _ from 'lodash'
import { useState, useCallback } from 'react'

import {
  Response,
  AllAccountStats,
  AccountStats,
  HistogramInterval,
  DateRange,
  Filters,
} from 'src/types'
import { SOCIAL_PLATFORMS } from 'src/utils/constants'
import { createEmptyPlatformStats } from 'src/utils/emptyStats'
import { store } from 'src/redux/store'

import client from '../client'

/**
 * Fill in the account stats, if there are none, for a specific platform.
 * Platforms will always be returned in alphabetical.
 *
 * Only platforms with tracked users will be returned, if there are no tracked
 * users, then all platforms will be returned.
 *
 * All Areto Scores are set to 1, and the comment totals are 0. Only the
 * current account stats will be returned with intervals, that are also
 * set to the defaults.
 *
 * @param stats the account stats to check for missing platforms
 * @param start the start date as an ISOString
 * @param end the end datetime as an ISOString
 * @param interval the type of interval (hour, day)
 * @param isCurrent whether this is the current account stats
 */
const fillInEmptyPlatformStats = (
  stats: AccountStats,
  start: string,
  end: string,
  interval: HistogramInterval,
  isCurrent: boolean
): AccountStats => {
  const trackedPlatforms = store.getState().user.trackedPlatforms
  const filteredTrackedAccounts = store.getState().filters.trackedAccounts

  const TRACKED_PLATFORMS: string[] =
    trackedPlatforms && trackedPlatforms.length > 0
      ? trackedPlatforms.slice().sort()
      : SOCIAL_PLATFORMS.slice().sort()

  const newStats = _.cloneDeep(stats)

  // If all platforms are tracked, then return the stats as is
  // or if there are tracked accounts, then return the stats as is
  if (
    newStats.platforms.length === TRACKED_PLATFORMS.length ||
    filteredTrackedAccounts.length >= 0
  ) {
    newStats.platforms = newStats.platforms.sort((a, b) =>
      a.key.localeCompare(b.key)
    )
    return newStats
  }

  // Fill in any missing platform stats
  TRACKED_PLATFORMS.forEach((platform, index) => {
    if (!newStats.platforms.find(p => p.key === platform)) {
      let defaultStats = createEmptyPlatformStats(
        platform,
        start,
        end,
        interval,
        isCurrent
      )
      newStats.platforms.push(defaultStats)
    }
  })

  newStats.platforms = newStats.platforms.sort((a, b) =>
    a.key.localeCompare(b.key)
  )

  return newStats
}

const parseData = (
  data: AllAccountStats,
  currentFilters: { dateRange: DateRange; trackedAccounts: number[] }
): AllAccountStats => {
  return {
    current: fillInEmptyPlatformStats(
      data.current,
      currentFilters.dateRange.start,
      currentFilters.dateRange.end,
      currentFilters.dateRange.interval,
      true
    ),
    previous: fillInEmptyPlatformStats(
      data.previous,
      currentFilters.dateRange.start,
      currentFilters.dateRange.end,
      currentFilters.dateRange.interval,
      false
    ),
  } as AllAccountStats
}

const createParams = (currentFilters: {
  dateRange: DateRange
  trackedAccounts: number[]
}): any => {
  return {
    start: currentFilters.dateRange.start,
    end: currentFilters.dateRange.end,
    interval: currentFilters.dateRange.interval,
    include_previous: true,
    ...(currentFilters.trackedAccounts &&
    currentFilters.trackedAccounts.length > 0
      ? { tracked_user_ids: currentFilters.trackedAccounts.join(',') }
      : {}),
  }
}

export const useFetchStats = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<AllAccountStats | null>(null)

  const fetch = useCallback(async (props: Filters) => {
    setLoading(true)

    try {
      const response = await client.get('/stats/dashboard', {
        params: createParams(props),
      })
      const data = response.data as Response<AllAccountStats>
      const parsedData = parseData(data.response, props)

      setData(parsedData)
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  return { error, loading, data, fetch }
}
