import { MantineTheme } from '@mantine/core'

export const components: MantineTheme['components'] = {
  AppShell: {
    styles: theme => ({
      main: {
        position: 'relative',
        '&:before': {
          content: "' '",
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          opacity: 0.28,
          backgroundImage: [
            "url('/flare/dots_right_x2_narrow.svg')",
            "url('/flare/dots_right_x5_narrow.svg')",
            "url('/flare/dots_bottom_x5_narrow.svg')",
          ].join(', '),
          backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
          backgroundPosition:
            'calc(100% - 2px) 600px, calc(100% - 2px) 1100px, 25% calc(100% - 2px)',
          userSelect: 'none',
          pointerEvents: 'none',
        },
      },
    }),
  },
  Avatar: {
    defaultProps: {
      radius: 'xl',
      src: '/profile-placeholder.svg',
    },
  },
  Badge: {
    defaultProps: {
      variant: 'outline',
      color: 'secondary',
    },
    styles: theme => ({
      root: {
        fontWeight: 400,
        padding: '6px 8px',
        lineHeight: 12,
      },
      inner: {
        textTransform: 'capitalize',
        letterSpacing: '2%',
      },
    }),
  },
  Button: {
    defaultProps: {
      radius: 'xl',
    },
    styles(theme, params, { variant }) {
      const baseStyles: Record<string, any> = {
        label: {
          fontWeight: 500,
        },
      }
      if (variant === 'filled') {
        baseStyles.root = {
          ...baseStyles.root,
          transition: 'transform 250ms ease-in-out',
          '&:hover': {
            backgroundColor:
              theme.colors[params.color || theme.primaryColor][5],
            transform: 'scale(1.05)',
          },
        }
        baseStyles.label.color = theme.colors.dark[6]
      } else if (variant === 'subtle') {
        baseStyles.root = {
          ...baseStyles.root,
          color: theme.colors.dark[6],
          padding: 0,
          '&:hover': {
            color: theme.colors.dark[0],
            background: 'transparent',
          },
          '&:disabled': {
            background: 'transparent',
            color: theme.colors.dark[3],
          },
        }
      } else if (variant === 'error') {
        baseStyles.root = {
          ...baseStyles.root,
          backgroundColor: theme.colors.error[0],
          color: 'white',
          transition: 'transform 250ms ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }
      } else if (variant === 'info') {
        baseStyles.root = {
          ...baseStyles.root,
          color: theme.colors.dark[4],
          backgroundColor: theme.colors.secondary[0],
          transition: 'transform 250ms ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }
      }
      return baseStyles
    },
  },
  Checkbox: {
    styles: theme => ({
      input: {
        // background: theme.colors.dark[7],
        border: `solid 2px ${theme.colors.primary[5]}`,
        borderRadius: theme.radius.xs,
      },
    }),
  },
  Grid: {
    styles: theme => ({
      col: {
        padding: 8,
      },
    }),
  },
  Input: {
    defaultProps: {
      radius: 'md',
    },
    styles: theme => ({
      input: {
        height: 48,
        background: theme.colors.dark[7],
        border: `solid 1px ${theme.colors.secondary[5]}`,
        '&::placeholder': {
          color: theme.colors.dark[3],
        },
      },
      required: {
        color: theme.colors.secondary[5],
      },
    }),
  },
  InputWrapper: {
    styles: theme => ({
      root: {
        position: 'relative',
      },
      label: {
        position: 'absolute',
        top: -12,
        left: 16,
        zIndex: 1,
        color: theme.colors.secondary[5],
        '& > *': {
          position: 'relative',
          zIndex: 1,
        },
        '&::before': {
          content: "''",
          position: 'absolute',
          zIndex: -1,
          top: 12,
          left: -4,
          width: 'calc(100% + 8px)',
          height: 1,
          background: theme.colors.dark[7],
        },
        required: {
          color: theme.colors.secondary[5],
        },
      },
    }),
  },
  Menu: {
    styles: theme => ({
      dropdown: {
        backgroundColor: theme.colors.light[1],
      },
      item: {
        color: theme.colors.dark[9],
        padding: 5,
        '&:hover': {
          backgroundColor: theme.colors.light[2],
        },
      },
    }),
  },
  Modal: {
    defaultProps: {
      radius: 'xl',
      shadows: 'xl',
    },
    styles: (theme, params) => ({
      modal: {
        position: 'relative',
      },
      content: {
        borderRadius: 24,
        // backgroundColor: theme.colors.light[1],
        backgroundColor: theme.colors.dark[7],
        color: theme.colors.dark[0],
        padding: '8 !important',
        minWidth: 500,
      },
      title: {
        // marginTop: 32,
        // marginRight: 40,
        // marginBottom: 8,
        fontSize: 24,
        // color: theme.colors.light[0],
        // backgroundColor: theme.colors.light[1],
        backgroundColor: theme.colors.dark[7],
        color: theme.colors.dark[0],
      },
      header: {
        // backgroundColor: theme.colors.light[1],
        backgroundColor: theme.colors.dark[7],
      },
      close: {
        position: 'absolute',
        // top: 32,
        right: 20,
        fontSize: 21,
        borderRadius: '100%',
        '& > svg': {
          width: 21,
          height: 21,
          color: theme.colors.light[0],
        },
        svg: {
          color: theme.colors.dark[0],
        },
      },
    }),
  },
  MultiSelect: {
    styles: theme => ({
      root: {
        minWidth: '200px',
        maxWidth: '500px',
      },
      item: {
        padding: 5,
      },
      values: {
        minHeight: '100%',
      },
      nothingFound: {
        color: theme.colors.dark[0],
      },
    }),
  },
  Notification: {
    styles: theme => ({
      root: {
        border: `solid 1px ${theme.colors.dark[4]}`,
      },
    }),
  },
  Pagination: {
    styles: theme => ({
      item: {
        '&[data-active]': {
          color: theme.colors.dark[6],
          fontWeight: 600,
        },
      },
    }),
  },
  Paper: {
    styles: theme => ({
      root: {
        border: `solid 1px ${theme.colors.dark[4]}`,
        padding: '24px !important', // This is being overriden :(
        borderRadius: 16,
      },
    }),
  },
  PasswordInput: {
    styles: theme => ({
      root: {
        '&:focus-within > label': {
          color: theme.colors[theme.primaryColor][theme.primaryShade as number],
        },
      },
      invalid: {
        '& > label': {
          color: theme.colors.error[0],
        },
      },
      innerInput: {
        height: 48,
        paddingLeft: 40,
      },
      icon: {
        width: 40,
      },
      required: {
        color: theme.colors.secondary[5],
      },
      error: {
        marginTop: 4,
      },
    }),
  },
  Progress: {
    styles: theme => ({
      root: {
        height: 14,
        background: theme.colors.dark[9],
        border: `solid 1px ${theme.colors.dark[5]}`,
      },
      bar: {
        background: theme.colors.secondary[6],
      },
    }),
  },
  Radio: {
    styles: theme => {
      const color =
        theme.colorScheme === 'dark'
          ? theme.colors[theme.primaryColor][theme.primaryShade as number]
          : theme.colors.dark[9]
      return {
        inner: {
          width: 'auto',
          height: 'auto',
          padding: 2,
          '& > svg': {
            display: 'none',
          },
        },
        radio: {
          width: 16,
          height: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'transparent',
          border: `solid 1px ${color}`,
          '&:after': {
            content: "' '",
            width: 12,
            height: 12,
            background: color,
            borderRadius: 6,
            opacity: 0,
          },
          '&:checked': {
            background: 'transparent',
            borderColor: color,
            '&:after': {
              opacity: 1,
            },
          },
        },
      }
    },
  },
  Select: {
    styles: theme => ({
      root: {
        '&:focus-within > label': {
          color: theme.colors[theme.primaryColor][theme.primaryShade as number],
        },
      },
      invalid: {
        '& > label': {
          color: theme.colors.error[0],
        },
      },
      required: {
        color: theme.colors.secondary[5],
      },
      item: {
        padding: 5,
      },
      label: {
        position: 'absolute',
        top: -12,
        left: 16,
        zIndex: 3,
        color: theme.colors.secondary[5],
        '& > *': {
          position: 'relative',
          zIndex: 1,
        },
        '&::before': {
          content: "''",
          position: 'absolute',
          zIndex: -1,
          top: 12,
          left: -4,
          width: 'calc(100% + 8px)',
          height: 1,
          background: theme.colors.dark[7],
        },
        required: {
          color: theme.colors.secondary[5],
        },
      },
    }),
  },
  Switch: {
    styles: theme => ({
      label: {
        fontSize: 14,
        fontWeight: 300,
      },
    }),
  },
  Tabs: {
    styles: theme => ({
      tabsList: {
        borderBottomWidth: 1,
        gap: 24,
      },
      tab: {
        borderBottom: 'none',
        padding: 0,
        paddingTop: 8,
        paddingBottom: 12,
        marginBottom: -1,
        color: theme.colors.dark[2],
        '&:hover': {
          background: 'transparent',
        },
        '&:after': {
          content: "' '",
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: 4,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        },
        '&[data-active]': {
          color: theme.colors[theme.primaryColor][theme.primaryShade as number],
          '&:after': {
            background:
              theme.colors[theme.primaryColor][theme.primaryShade as number],
          },
        },
      },
    }),
  },
  Text: {
    styles(theme, params, { variant }) {
      let baseStyles: Record<string, any> = {
        // label: {
        //   fontWeight: 500,
        // },
      }
      if (variant === 'yellow') {
        baseStyles.root = {
          ...baseStyles.root,
          color: theme.colors.primary[6],
        }
      }
      return baseStyles
    },
  },
  TextInput: {
    styles: theme => ({
      root: {
        '&:focus-within > label': {
          color: theme.colors[theme.primaryColor][theme.primaryShade as number],
        },
      },
      invalid: {
        '& > label': {
          color: theme.colors.error[0],
        },
      },
      input: {
        paddingLeft: 40,
      },
      icon: {
        width: 40,
      },
      required: {
        color: theme.colors.secondary[5],
      },
      error: {
        marginTop: 4,
      },
    }),
  },
  Tooltip: {
    styles: theme => ({
      tooltip: {
        background: theme.colors.light[1],
        color: theme.colors.dark[9],
        padding: 10,
      },
    }),
  },
}
