import { useCallback } from 'react'

import { Button, Group, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { notifications } from '@mantine/notifications'

import { ActionType } from 'src/types'
import { useSendCommentAuthorAction } from 'src/api'

interface UnmuteModalProps {
  trackedUserId: number
  commentAuthor: string
  commentAuthorId: string
  successCallback: () => void
}

export const UnmuteModal = (props: UnmuteModalProps) => {
  const { loading, send } = useSendCommentAuthorAction()

  const sendAction = useCallback(async () => {
    const success = await send(
      props.trackedUserId,
      props.commentAuthor,
      props.commentAuthorId,
      ActionType.UNMUTE
    )

    modals.closeAll()

    if (success) {
      props.successCallback()

      notifications.show({
        title: 'Success!',
        message: `${props.commentAuthor} has been unmuted.`,
        color: 'teal',
      })
    } else {
      notifications.show({
        title: 'Uh-oh! Something Went Wrong',
        message:
          'We were unable to mute this commenter. Please try again later.',
      })
    }
  }, [send, props])

  return (
    <>
      <Text color="white">
        Would you like to unmute <b>{props.commentAuthor}</b>? All future
        comments by commenters that are unmuted will be visible to your
        audience.
      </Text>
      <Group position="right" mt={16}>
        <Button
          variant="outline"
          disabled={loading}
          onClick={() => modals.closeAll()}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          loading={loading}
          loaderPosition="center"
          onClick={sendAction}
        >
          Unmute
        </Button>
      </Group>
    </>
  )
}
