import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid'
import { Icon } from '@iconify/react'
import { modals } from '@mantine/modals'

import { PlatformUsername, AbuseTags, MuteModal } from 'src/components'
import { createCommenterProfileLink } from 'src/utils/socials'
import { PlatformType } from 'src/types'

export const TROLL_FINDER_COLUMNS: GridColDef[] = [
  {
    field: 'commentAuthor',
    headerName: 'Commenter'.toLocaleUpperCase(),
    sortable: false,
    flex: 2,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return (
        <PlatformUsername
          platform={params.row.platform}
          username={params.row.platformUsername}
        />
      )
    },
  },
  {
    field: 'trackedUser',
    headerName: 'To'.toLocaleUpperCase(),
    sortable: false,
    flex: 2,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return (
        <PlatformUsername
          platform={params.value.platform}
          username={params.value.platformUsername}
        />
      )
    },
  },
  {
    field: 'totalComments',
    headerName: '# Abusive'.toLocaleUpperCase(),
    sortable: false,
    flex: 1.25,
    disableColumnMenu: true,
  },
  {
    field: 'abuseTags',
    headerName: 'Abuse Types'.toLocaleUpperCase(),
    sortable: false,
    flex: 2,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return (
        <>
          {params.value !== null && (
            <AbuseTags tags={params.value} numToShow={1} />
          )}
        </>
      )
    },
  },
]

export const EXPANDED_TROLL_FINDER_COLUMNS: GridColDef[] = [
  ...TROLL_FINDER_COLUMNS.filter(column => column.field !== 'more'), // Remove the existing 'more' column,
  {
    field: 'actions',
    type: 'actions',
    width: 60,
    getActions: (params: GridRowParams) => {
      let actions = [
        <GridActionsCellItem
          icon={<Icon icon="mdi:account-off-outline" />}
          onClick={() =>
            modals.open({
              title: 'Mute Commenter',
              centered: true,
              children: (
                <MuteModal
                  trackedUserId={params.row.trackedUser.id}
                  commentAuthor={params.row.platformUsername}
                  commentAuthorId={params.row.platformId}
                />
              ),
              size: 'md',
            })
          }
          label="Mute Commenter"
          showInMenu
        />,
      ]

      if (
        params.row.platform &&
        params.row.platformUsername &&
        params.row.platform !== PlatformType.FACEBOOK
      ) {
        const profileLink = createCommenterProfileLink(
          params.row.platform,
          params.row.platformUsername
        )

        actions.push(
          <GridActionsCellItem
            icon={<Icon icon="mdi:identification-card-outline" />}
            onClick={() => window.open(profileLink, '_blank')}
            label="View Profile"
            showInMenu
          />
        )
      }

      return actions
    },
  },
]
