import { Card, Title, Grid, Space, Text } from '@mantine/core'

import {
  Anchor,
  AretoScoreChartCard,
  TrollFinderCard,
  FanFinderCard,
} from 'src/components'
import { CommentAuthor, Filters, AllAccountStats } from 'src/types'

interface BreakdownProps {
  filters: Filters
  trolls: CommentAuthor[]
  fans: CommentAuthor[]
  trackedPlatforms: string[]
  accountStats: AllAccountStats
}

export const Breakdown = (props: BreakdownProps) => {
  return (
    <section className="page-section">
      <header>
        <Title order={2}>Breakdown</Title>
      </header>
      <Grid gutter="lg" grow>
        <Grid.Col span={6}>
          <FanFinderCard expanded={false} fans={props.fans} />
          <Space h="md" />
          <TrollFinderCard expanded={false} trolls={props.trolls} />
        </Grid.Col>

        <Grid.Col span={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: window.innerWidth > 1130 ? 'block' : 'none' }}>
            <Card shadow="xl" radius="lg" p="xl">
              <Text size={18} weight={300} align="left">
                Use the date and account filters to track positive sentiment
                trends across your connected social media platforms. We&apos;re
                always improving these features, so for deeper insights or
                requests, contact us at{' '}
                <Anchor to={`mailto:support@aretolabs.com`}>
                  support@aretolabs.com
                </Anchor>
                —your feedback drives future updates!
              </Text>
            </Card>
            <Space h="md" />
          </div>
          <div
            style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
          >
            <AretoScoreChartCard
              accountStats={props.accountStats}
              trackedPlatforms={props.trackedPlatforms}
              filters={props.filters}
            />
          </div>
        </Grid.Col>
      </Grid>
    </section>
  )
}
