import { useCallback } from 'react'

import { Group, Button, Grid } from '@mantine/core'
import { modals } from '@mantine/modals'
import { AbuseTags } from 'src/components'
import {
  ModerationResolveTypeLabels,
  ActionLogEntry,
  PlatformTypeLabels,
} from 'src/types'
import { parseToLocalDateTime } from 'src/utils/date'
import { useSendTranslateComment } from 'src/api'

interface DetailRowProps {
  label: string
  value: string | React.ReactNode
  titleSpan?: number
  contentSpan?: number
  padding?: string
}

const DetailRow: React.FC<DetailRowProps> = ({
  label,
  value,
  titleSpan = 4,
  contentSpan = 8,
  padding = '0 0 8px 0',
}) => {
  return (
    <Grid>
      <Grid.Col span={titleSpan} style={{ padding: padding }}>
        {label}
      </Grid.Col>
      <Grid.Col span={contentSpan} style={{ padding: padding }}>
        {value}
      </Grid.Col>
    </Grid>
  )
}

export const CommentModal = ({ entry }: { entry: ActionLogEntry }) => {
  const {
    loading: loadingTranslate,
    data: translatedText,
    send: sendTranslate,
    reset: resetTranslate,
  } = useSendTranslateComment(entry?.comment.text || '')

  const toggleTranslate = useCallback(() => {
    if (translatedText) {
      resetTranslate()
    } else {
      sendTranslate()
    }
  }, [resetTranslate, sendTranslate, translatedText])

  if (!entry) return <div></div>
  return (
    <>
      <DetailRow label="TO:" value={entry.trackedUser.platformUsername} />
      <DetailRow label="FROM:" value={entry.comment.author} />
      <DetailRow
        label="PLATFORM:"
        value={PlatformTypeLabels[entry.trackedUser.platform]}
      />
      <DetailRow
        label="COMMENT:"
        value={translatedText ? translatedText : entry.comment.text}
      />
      <DetailRow
        label="POSTED:"
        value={parseToLocalDateTime(entry.comment.timestamp)}
      />
      {entry.resolution && (
        <DetailRow
          label="STATUS:"
          value={
            ModerationResolveTypeLabels[entry.resolution.status] || 'Unresolved'
          }
        />
      )}
      {entry.resolution && entry.resolution.timestamp && (
        <DetailRow
          label="RESOLVED:"
          value={parseToLocalDateTime(entry.resolution.timestamp)}
        />
      )}
      {entry.abuseTags && entry.abuseTags.length > 0 && (
        <DetailRow
          label="ABUSE TYPES:"
          value={
            <AbuseTags tags={entry.abuseTags} expanded={true} isDark={true} />
          }
        />
      )}
      <Group position="right" mt={16}>
        <Button variant="outline" onClick={() => modals.closeAll()}>
          Cancel
        </Button>
        <Button
          variant="filled"
          loading={loadingTranslate}
          onClick={toggleTranslate}
        >
          {translatedText ? 'Show Original' : 'Translate'}
        </Button>
        <Button
          variant="filled"
          onClick={() =>
            navigator.clipboard.writeText(
              translatedText ? translatedText : entry.comment.text
            )
          }
        >
          Copy
        </Button>
      </Group>
    </>
  )
}
