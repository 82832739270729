import { useState, useCallback } from 'react'

import {
  Response,
  ActionLogEntry,
  ModerationResolveType,
  AbuseType,
  ActionLogResponse,
  Filters,
  Pagination,
  ActionLogSortModel,
  ActionLog,
} from 'src/types'

import client from '../client'

export interface FetchActionLogProps {
  globalFilters: Filters
  isUnresolved: boolean
  abuseTypeFilter: string
  pagination: Pagination
  sortModel: ActionLogSortModel
  resolvedTypeFilter: string
}

const createParams = (props: FetchActionLogProps): any => {
  const resolvedStatuses = props.isUnresolved
    ? ['UNRESOLVED']
    : props.resolvedTypeFilter === 'ALL'
      ? []
      : [props.resolvedTypeFilter]

  return {
    start: props.globalFilters.dateRange!.start,
    end: props.globalFilters.dateRange!.end,
    page: props.pagination.currentPage,
    per_page: props.pagination.perPage,
    sort_by: props.sortModel.sortBy,
    order: props.sortModel.sortOrder,
    ...(props.globalFilters.trackedAccounts &&
    props.globalFilters.trackedAccounts.length > 0
      ? { tracked_user_ids: props.globalFilters.trackedAccounts.join(',') }
      : {}),
    ...(resolvedStatuses && resolvedStatuses.length > 0
      ? { resolved_statuses: resolvedStatuses.join(',') }
      : {}),
    ...(props.abuseTypeFilter !== 'ALL'
      ? { abuse_types: props.abuseTypeFilter }
      : {}),
    ...(props.globalFilters.platform !== 'ALL'
      ? { platforms: props.globalFilters.platform }
      : {}),
  }
}

const parseActionLogEntries = (
  data: Response<ActionLogResponse>
): ActionLogEntry[] => {
  return data.response.entries.map(entry => {
    return {
      ...entry,
      abuseTags: entry.abuseTags.map(tag => AbuseType[tag]),
      resolution: {
        ...entry.resolution,
        status: ModerationResolveType[entry.resolution.status],
      },
    }
  })
}

export const useFetchActionLog = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  // const [data, setData] = useState<ActionLogEntry[] | null>(null)

  const fetch = useCallback(
    async (props: FetchActionLogProps): Promise<ActionLog> => {
      setLoading(true)

      try {
        const response = await client.get('/moderation/queue', {
          params: createParams(props),
        })
        const data = response.data as Response<ActionLogResponse>
        const parsedEntries = parseActionLogEntries(data)
        // setData(parsedEntries)

        return {
          entries: parsedEntries,
          totalEntries: data.response.totalEntries,
          totalPages: data.response.totalPages,
        }
      } catch (error: any) {
        setError(error.message)

        return {
          entries: [],
          totalEntries: 0,
          totalPages: 0,
        }
      } finally {
        setLoading(false)
      }
    },
    []
  )

  // fetch data when filters change and reset the currentPage to 1 in both action logs
  // useEffect(() => {
  //   if (auto) {
  //     store.dispatch(resetAllPaginationToPageOne())
  //     fetchData(1)
  //   }
  // }, [
  //   fetchData,
  //   auto,
  //   currentFilters,
  //   actionLogs.abuseTypeFilter,
  //   actionLogs.resolvedTypeFilter,
  // ])

  // // fetch data with current filters on tab change
  // useEffect(() => {
  //   if (auto) fetchData()
  // }, [fetchData, auto, actionLogs.unresolvedTab])

  // // fetch data with current filters on page/size change
  // useEffect(() => {
  //   if (auto && actionLogs.paginationUpdated) {
  //     fetchData()
  //     store.dispatch(setActionLogPaginationUpdated(false))
  //   }
  // }, [fetchData, auto, actionLogs.paginationUpdated])

  // const refetch = async () => {
  //   await fetchData()
  // }

  return { error, loading, fetch }
}
