import { useState, useEffect, useCallback } from 'react'

import {
  Card,
  Badge,
  Select,
  Space,
  Group,
  Text,
  Divider,
  createStyles,
} from '@mantine/core'
import { ChartConfiguration } from 'chart.js'

import { Chart, NumericText } from 'src/components'
import { theme } from 'src/styling/theme'
import { PercentFormat, SOCIAL_PLATFORMS } from 'src/utils/constants'
import { getDateIntervalLabel } from 'src/utils/date'
import {
  DatePreset,
  HistogramInterval,
  PlatformStats,
  PlatformTypeLabels,
  Filters,
  AllAccountStats,
} from 'src/types'
import { calculatePercentageChange } from 'src/utils/formulas'

interface AretoScoreChartCardProps {
  filters: Filters
  trackedPlatforms: string[]
  accountStats: AllAccountStats
}

const useStyles = createStyles(theme => ({
  breakdownPercentPositiveChange: {
    background: 'rgba(92, 224, 217, 0.08)',
    color: '#5CE0D9',
    fontSize: 14,
    paddingBottom: 7,
    borderRadius: 8,
    border: 0,
  },
  breakdownPercentNegativeChange: {
    background: 'rgba(255, 203, 0, 0.08)',
    color: '#FFCB00',
    fontSize: 14,
    paddingBottom: 7,
    borderRadius: 8,
    border: 0,
  },
}))

// TODO: link ChartColor to platform
const ChartColors = ['#C19A0C', '#FFCB00', '#f4d152', '#f9e499']

export const AretoScoreChartCard = (props: AretoScoreChartCardProps) => {
  const { classes } = useStyles()

  const [aretoScoreChange, setAretoScoreChange] = useState<number>(0)
  const [aretoScoreFilter, setAretoScoreFilter] = useState<string>('ALL')

  const TRACKED_PLATFORMS =
    props.trackedPlatforms && props.trackedPlatforms.length > 0
      ? props.trackedPlatforms
      : SOCIAL_PLATFORMS

  // if there are tracked accounts, set the areto score filter to ALL
  useEffect(() => {
    if (props.filters.trackedAccounts.length !== 0) {
      setAretoScoreFilter('ALL')
    }
  }, [props.filters])

  const _getAretoScore = () => {
    let aretoScore = 1

    if (aretoScoreFilter === 'ALL')
      aretoScore = props.accountStats.current.aretoScore
    else
      props.accountStats.current.platforms.forEach(platform => {
        if (aretoScoreFilter === platform.key) aretoScore = platform.aretoScore
      })

    return PercentFormat.format(aretoScore)
  }

  const _setAretoScoreChange = useCallback(() => {
    if (aretoScoreFilter === 'ALL') {
      const change = calculatePercentageChange(
        props.accountStats.current.aretoScore,
        props.accountStats.previous.aretoScore
      )

      setAretoScoreChange(change)
    } else {
      props.accountStats.current.platforms.forEach(platform => {
        if (aretoScoreFilter === platform.key) {
          const previousAretoScore = props.accountStats.previous.platforms.find(
            p => {
              if (p.key === platform.key) return true
              return false
            }
          )!.aretoScore

          const change = calculatePercentageChange(
            platform.aretoScore,
            previousAretoScore
          )
          setAretoScoreChange(change)
        }
      })
    }
  }, [props.accountStats, aretoScoreFilter])

  useEffect(() => {
    _setAretoScoreChange()
  }, [props.accountStats, _setAretoScoreChange])

  const _transformChartStats = () => {
    return props.accountStats.current.platforms
      .filter(platform => {
        if (aretoScoreFilter === 'ALL') return true
        else if (aretoScoreFilter === platform.key) return true
        return false
      })
      .map((stat: PlatformStats, index: number) => {
        let intervals = stat.intervals
        let dataWithConvertedIntervals

        if (
          props.filters.dateRange.key === DatePreset.custom &&
          props.filters.dateRange.interval === HistogramInterval.Day
        ) {
          // remove the last interval with custom range (from date picker)
          intervals = intervals.slice(0, -1)
        } else if (props.filters.dateRange.key === DatePreset.last24Hours) {
          intervals = stat.intervals.filter(s => {
            return new Date(s.key).getHours() % 3 === 0
          })
          // remove the first interval, so there aren't two points with same key
          intervals = intervals.slice(1)
        }

        if (
          props.filters.dateRange.key === DatePreset.last24Hours ||
          props.filters.dateRange.key === DatePreset.today ||
          (props.filters.dateRange.key === DatePreset.custom &&
            props.filters.dateRange.interval === HistogramInterval.Hour)
        ) {
          dataWithConvertedIntervals = intervals.map(s => {
            const utcDate = new Date(s.key)
            const localDate = new Date(utcDate.getTime())

            return {
              x: getDateIntervalLabel(
                localDate,
                props.filters.dateRange.key === DatePreset.last24Hours
                  ? 'custom'
                  : props.filters.dateRange.interval
              ),
              y: Math.round(s.aretoScore * 100),
            }
          })
        } else {
          dataWithConvertedIntervals = intervals.map(s => {
            const utcDate = new Date(s.key)
            const timezoneOffsetInMinutes = utcDate.getTimezoneOffset()
            const timezoneOffsetInMilliseconds =
              timezoneOffsetInMinutes * 60 * 1000
            const localDate = new Date(
              utcDate.getTime() + timezoneOffsetInMilliseconds
            )

            return {
              x: getDateIntervalLabel(
                localDate,
                props.filters.dateRange.interval
              ),
              y: Math.round(s.aretoScore * 100),
            }
          })
        }

        return {
          label: stat.key,
          borderColor: ChartColors[index],
          pointBackgroundColor: [ChartColors[index]],
          fill: true,
          data: dataWithConvertedIntervals,
        }
      })
  }

  const chartOptions: ChartConfiguration = {
    type: 'line',
    data: {
      //@ts-ignore
      datasets: _transformChartStats(),
    },
    options: {
      maintainAspectRatio: false,
      animation: false,
      scales: {
        x: {
          ticks: {
            font: {
              size: 10,
            },
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            font: {
              size: 10,
            },
            callback(value, index, ticks) {
              if ([0, 50, 100].includes(value as number)) return value
              return null
            },
          },
          afterDataLimits(axis) {
            // Adding some padding so points at 100% don't get clipped
            // Disabling this so we still get the 0,50,100 yAxis labels
            axis.max = 100
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: context => {
              return (
                ' ' +
                PlatformTypeLabels[context.dataset.label!] +
                ': ' +
                context.parsed.y +
                '%'
              )
            },
          },
        },
      },
    },
  }

  return (
    <Card
      shadow="xl"
      radius="lg"
      p="xl"
      style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
    >
      <Badge
        className={
          aretoScoreChange >= 0
            ? classes.breakdownPercentPositiveChange
            : classes.breakdownPercentNegativeChange
        }
        size="lg"
        sx={{ width: 'fit-content' }}
      >
        {aretoScoreChange > 0 && '+'}

        {PercentFormat.format(aretoScoreChange / 100)}
      </Badge>
      <Space h="sm" />
      <Group position="apart">
        <Group>
          <NumericText size={44} weight={700}>
            {_getAretoScore()}
          </NumericText>
          <div>
            <Text size="sm" color={theme.colors.dark[2]}>
              Positive
            </Text>
            <Text size="xs" color={theme.colors.dark[2]} transform="uppercase">
              Overall Sentiment
            </Text>
          </div>
        </Group>
        {props.filters.trackedAccounts.length === 0 && (
          <Select
            data={Object.entries(PlatformTypeLabels)
              .filter(([key, value]) => {
                if (TRACKED_PLATFORMS.includes(key) || key === 'ALL')
                  return true
                return false
              })
              .map(([key, value]) => ({
                label: value,
                value: key,
              }))}
            value={aretoScoreFilter}
            onChange={v => setAretoScoreFilter(v!)}
          />
        )}
      </Group>
      <Space h="sm" />
      <Divider />
      <Space h="lg" />
      <Chart width="100%" height="308px" options={chartOptions} />
    </Card>
  )
}
