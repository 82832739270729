import { useState, useCallback } from 'react'

import {
  Response,
  ModerationPreviewResponse,
  AbuseType,
  DateRange,
  ModerationPreview,
  Filters,
} from 'src/types'

import client from '../client'

const parseData = (data: Response<ModerationPreviewResponse>) => {
  return {
    toActionAbuseTags: data.response.abuseTagsUnresolved.map(
      tag => AbuseType[tag]
    ),
    moderatedAbuseTags: data.response.abuseTagsResolved.map(
      tag => AbuseType[tag]
    ),
    toActionTotal: data.response.totalUnresolved,
    moderatedTotal: data.response.totalResolved,
  }
}

const createParams = (props: {
  dateRange: DateRange
  trackedAccounts: number[]
}) => {
  return {
    start: props.dateRange.start,
    end: props.dateRange.end,
    ...(props.trackedAccounts && props.trackedAccounts.length > 0
      ? { tracked_user_ids: props.trackedAccounts.join(',') }
      : {}),
  }
}

export const useFetchModerationPreview = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<ModerationPreview | null>(null)

  const fetch = useCallback(async (props: Filters) => {
    setLoading(true)

    try {
      const response = await client.get('/stats/preview', {
        params: createParams(props),
      })
      const data = response.data as Response<ModerationPreviewResponse>
      const parsedData = parseData(data)

      setData(parsedData)
    } catch (error: any) {
      setError(error.message)

      console.log(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  return { error, loading, data, fetch }
}
