import { GridToolbarContainer } from '@mui/x-data-grid'
import { Group, Button, Select, createStyles } from '@mantine/core'
import { modals } from '@mantine/modals'

import {
  ModerationResolveTypeSelect,
  ModerationActionType,
  AbuseTypeLabels,
  ActionLogSettings,
} from 'src/types'
import { ActionModal } from 'src/components'

const useStyles = createStyles(theme => ({
  toolbar: {
    width: '100%',
    marginBottom: 8,
  },
}))

const openActionModal = (
  title: string,
  action: ModerationActionType,
  settings: ActionLogSettings,
  updateSettings: (settings: ActionLogSettings) => void,
  refetchActionLogData: () => void
) => {
  modals.open({
    title: title,
    centered: true,
    children: (
      <ActionModal
        action={action}
        settings={settings}
        updateSettings={updateSettings}
        refetchActionLogData={refetchActionLogData}
      />
    ),
    size: 'md',
  })
}

interface ActionLogTableToolbarProps {
  settings: ActionLogSettings
  updateSettings: (settings: ActionLogSettings) => void
  refetchActionLogData: () => void
}

const UnresolvedToolbar = ({
  settings,
  updateSettings,
  refetchActionLogData,
}: ActionLogTableToolbarProps) => {
  const { classes } = useStyles()

  return (
    <Group className={classes.toolbar} position="apart">
      {settings.selectedIds.length > 0 ? (
        <Group>
          <Button
            variant="outline"
            onClick={() =>
              openActionModal(
                'Confirm: Hide',
                ModerationActionType.HIDE,
                settings,
                updateSettings,
                refetchActionLogData
              )
            }
          >
            Hide
          </Button>
          <Button
            variant="outline"
            onClick={() =>
              openActionModal(
                'Confirm: Delete',
                ModerationActionType.DELETE,
                settings,
                updateSettings,
                refetchActionLogData
              )
            }
          >
            Delete
          </Button>
          <Button
            variant="outline"
            onClick={() =>
              openActionModal(
                'Confirm: Not Abusive',
                ModerationActionType.FALSE_POSITIVE,
                settings,
                updateSettings,
                refetchActionLogData
              )
            }
          >
            Not Abusive
          </Button>
          <Button
            variant="outline"
            onClick={() =>
              openActionModal(
                'Confirm: Archive',
                ModerationActionType.ARCHIVE,
                settings,
                updateSettings,
                refetchActionLogData
              )
            }
          >
            Archive
          </Button>
        </Group>
      ) : (
        <div></div>
      )}

      <Select
        data={Object.entries(AbuseTypeLabels).map(([key, value]) => ({
          label: value,
          value: key,
        }))}
        placeholder="Status"
        value={settings.abuseTypeFilter}
        onChange={v => updateSettings({ ...settings, abuseTypeFilter: v! })}
      />
    </Group>
  )
}

const ResolvedToolbar = ({
  settings,
  updateSettings,
  refetchActionLogData,
}: ActionLogTableToolbarProps) => {
  const { classes } = useStyles()

  return (
    <Group className={classes.toolbar} position="apart">
      {settings.selectedIds.length > 0 ? (
        <Group>
          <Button
            variant="outline"
            onClick={() =>
              openActionModal(
                'Confirm: Undo Hide',
                ModerationActionType.UNDO_HIDE,
                settings,
                updateSettings,
                refetchActionLogData
              )
            }
          >
            Undo Hide
          </Button>
          <Button
            variant="outline"
            onClick={() =>
              openActionModal(
                'Confirm: Unarchive',
                ModerationActionType.UNARCHIVE,
                settings,
                updateSettings,
                refetchActionLogData
              )
            }
          >
            Unarchive
          </Button>
        </Group>
      ) : (
        <div></div>
      )}

      <Group>
        <Select
          data={Object.entries(AbuseTypeLabels).map(([key, value]) => ({
            label: value,
            value: key,
          }))}
          placeholder="Status"
          value={settings.abuseTypeFilter}
          onChange={v => updateSettings({ ...settings, abuseTypeFilter: v! })}
        />

        <Select
          data={Object.entries(ModerationResolveTypeSelect).map(
            ([key, value]) => ({
              label: value,
              value: key,
            })
          )}
          placeholder="Status"
          value={settings.resolvedTypeFilter}
          onChange={v =>
            updateSettings({ ...settings, resolvedTypeFilter: v! })
          }
        />
      </Group>
    </Group>
  )
}

export const ActionLogTableToolbar = (props: ActionLogTableToolbarProps) => {
  const { classes } = useStyles()

  return (
    <GridToolbarContainer>
      <Group className={classes.toolbar} position="apart">
        {props.settings.isUnresolved ? (
          <UnresolvedToolbar {...props} />
        ) : (
          <ResolvedToolbar {...props} />
        )}
      </Group>
    </GridToolbarContainer>
  )
}
