import { useState, useCallback } from 'react'
import {
  Response,
  TrackedUsersStatsResponse,
  DateRange,
  Filters,
} from 'src/types'

import client from '../client'

const createParams = (props: {
  dateRange: DateRange
  trackedAccounts: number[]
}) => {
  return {
    start: props.dateRange.start,
    end: props.dateRange.end,
    ...(props.trackedAccounts && props.trackedAccounts.length > 0
      ? { tracked_user_ids: props.trackedAccounts.join(',') }
      : {}),
  }
}

export const useFetchTrackedUsersStats = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<TrackedUsersStatsResponse | null>(null)

  const fetch = useCallback(async (props: Filters) => {
    setLoading(true)

    try {
      const response = await client.get('/stats/v2/tracked-users', {
        params: createParams(props),
      })

      const data = response.data as Response<TrackedUsersStatsResponse>
      setData(data.response)
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  return { error, loading, data, fetch }
}
