import {
  DataGrid,
  GridRowSelectionModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid'

import { ActionLogTableToolbar } from './ActionLogTableToolbar'
import { ActionLogTableEmpty } from './ActionLogTableEmpty'
import { createColumnData } from './ActionLogTableColumns'
import {
  ActionLog,
  ActionLogSortBy,
  ActionLogSortOrder,
  ActionLogSettings,
} from 'src/types'

interface ActionLogTableProps {
  loading: boolean
  actionLog: ActionLog
  settings: ActionLogSettings
  updateSettings: (settings: ActionLogSettings) => void
  refetchActionLogData: () => void
}

export const ActionLogTable = (props: ActionLogTableProps) => {
  const handleSortModelChange = (sortModel: GridSortModel) => {
    const sortBy =
      ActionLogSortBy[sortModel[0].field as keyof typeof ActionLogSortBy]
    const sortOrder =
      ActionLogSortOrder[sortModel[0].sort as keyof typeof ActionLogSortOrder]

    props.updateSettings({
      ...props.settings,
      sortModel: {
        sortBy,
        sortOrder,
      },
    })
  }

  const handleRowSelectionModelChange = (model: GridRowSelectionModel) => {
    props.updateSettings({
      ...props.settings,
      selectedIds: model as number[],
    })
  }

  const updatePagination = (model: GridPaginationModel) => {
    // Scroll to the top of the page
    window.scrollTo(0, 0)

    props.updateSettings({
      ...props.settings,
      pagination: {
        currentPage: model.page + 1,
        perPage: model.pageSize,
      },
    })
  }

  const entries = props.actionLog.entries.map(entry => {
    return {
      id: entry.id,
      trackedUser: entry.trackedUser,
      commentAuthor: entry.comment.author,
      comment: entry.comment.text,
      commentLanguage: entry.comment.language,
      commentPosted: entry.comment.timestamp,
      abuseTags: entry.abuseTags,
      resolutionStatus: entry.resolution,
      entry: entry,
    }
  })

  return (
    <DataGrid
      sx={{
        '& .MuiDataGrid-row:hover': {
          backgroundColor: 'inherit',
        },
        '--DataGrid-overlayHeight': '300px',
        '.MuiDataGrid-actionsCell': {
          gridGap: 2,
        },
      }}
      autoHeight
      rows={entries}
      columns={createColumnData(props)}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
        sorting: {
          sortModel: [
            {
              field: 'commentPosted',
              sort: 'desc',
            },
          ],
        },
      }}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      sortingOrder={['desc', 'asc']}
      pageSizeOptions={[10, 25, 50, 100]}
      checkboxSelection
      rowCount={props.actionLog.totalEntries}
      disableColumnMenu
      slots={{
        toolbar: toolbarProps => (
          <ActionLogTableToolbar
            {...toolbarProps}
            settings={props.settings}
            updateSettings={props.updateSettings}
            refetchActionLogData={props.refetchActionLogData}
          />
        ),
        noRowsOverlay: ActionLogTableEmpty,
      }}
      onPaginationModelChange={updatePagination}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      rowSelectionModel={props.settings.selectedIds}
      paginationMode="server"
      paginationModel={{
        pageSize: props.settings.pagination.perPage,
        page: props.settings.pagination.currentPage - 1,
      }}
      loading={props.loading}
      // keepNonExistentRowsSelected // for keeping ids with pagination -- buggy for filters
    />
  )
}
