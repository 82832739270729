import { useState, useCallback } from 'react'

import { Response, ActionLogTotalResponse, Filters } from 'src/types'

import client from '../client'

export interface FetchActionLogTotalsProps {
  isUnresolved: boolean
  globalFilters: Filters
  abuseTypeFilter: string
  resolvedTypeFilter: string
}

export const useFetchActionLogTotals = () => {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  // const [data, setData] = useState<ActionLogTotals | null>(null)

  const _createParams = (props: FetchActionLogTotalsProps): any => {
    return {
      start: props.globalFilters.dateRange!.start,
      end: props.globalFilters.dateRange!.end,
      ...(props.globalFilters.trackedAccounts &&
      props.globalFilters.trackedAccounts.length > 0
        ? { tracked_user_ids: props.globalFilters.trackedAccounts.join(',') }
        : {}),
      ...(props.resolvedTypeFilter !== 'ALL'
        ? { resolved_statuses: props.resolvedTypeFilter }
        : {}),
      ...(props.abuseTypeFilter !== 'ALL'
        ? { abuse_types: props.abuseTypeFilter }
        : {}),
      ...(props.globalFilters.platform !== 'ALL'
        ? { platforms: props.globalFilters.platform }
        : {}),
    }
  }

  const fetch = useCallback(
    async (props: FetchActionLogTotalsProps): Promise<number> => {
      setLoading(true)

      try {
        const response = await client.get('/stats/queue/totals', {
          params: _createParams(props),
        })

        const data = response.data as Response<ActionLogTotalResponse>
        return data.response.total
        // setData(totals)
      } catch (error: any) {
        setError(error.message)
        return 0
      } finally {
        setLoading(false)
      }
    },
    []
  )

  return { error, loading, fetch }
}
