import { Tabs, createStyles } from '@mantine/core'

import { ActionLogTable } from './ActionLogTable'
import { ActionLog, ActionLogTotals, ActionLogSettings } from 'src/types'

const useStyles = createStyles(theme => ({
  navContainer: {
    position: 'relative',
    marginBottom: theme.spacing.lg,
  },
}))

interface ActionLogTabsProps {
  loadingData: boolean
  isUnresolvedTab: boolean
  setIsUnresolvedTab: (isUnresolvedTab: boolean) => void
  actionLogTotals: ActionLogTotals
  unresolvedActionLog: ActionLog
  unresolvedActionLogSettings: ActionLogSettings
  setUnresolvedActionLogSettings: (actionLog: ActionLogSettings) => void
  resolvedActionLog: ActionLog
  resolvedActionLogSettings: ActionLogSettings
  setResolvedActionLogSettings: (actionLog: ActionLogSettings) => void
  refetchActionLogData: () => void
}

export const ActionLogTabs: React.FC<ActionLogTabsProps> = (
  props: ActionLogTabsProps
) => {
  const { classes } = useStyles()

  return (
    <Tabs
      onTabChange={(value: string) =>
        props.setIsUnresolvedTab(value === 'unresolved' ? true : false)
      }
      value={props.isUnresolvedTab ? 'unresolved' : 'resolved'}
    >
      <div className={classes.navContainer}>
        <Tabs.List>
          <Tabs.Tab key="unresolved-tab" value="unresolved">
            To Action ({props.actionLogTotals.unresolved})
          </Tabs.Tab>
          <Tabs.Tab key="resolved-tab" value="resolved">
            Moderated ({props.actionLogTotals.resolved})
          </Tabs.Tab>
        </Tabs.List>
      </div>

      <Tabs.Panel value="unresolved">
        <ActionLogTable
          loading={props.loadingData}
          actionLog={props.unresolvedActionLog}
          settings={props.unresolvedActionLogSettings}
          updateSettings={props.setUnresolvedActionLogSettings}
          refetchActionLogData={props.refetchActionLogData}
        />
      </Tabs.Panel>
      <Tabs.Panel value="resolved">
        <ActionLogTable
          loading={props.loadingData}
          actionLog={props.resolvedActionLog}
          settings={props.resolvedActionLogSettings}
          updateSettings={props.setResolvedActionLogSettings}
          refetchActionLogData={props.refetchActionLogData}
        />
      </Tabs.Panel>
    </Tabs>
  )
}
