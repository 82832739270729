import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Group, Space, Title } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { Page, Loading, ActionLogTabs, ActionLogFilters } from 'src/components'
import { RootState } from 'src/redux/reducers'
import {
  useFetchActionLog,
  useFetchActionLogTotals,
  FetchActionLogProps,
} from 'src/api'
import {
  ActionLogTotals,
  ActionLog,
  ActionLogSortBy,
  ActionLogSortOrder,
  ActionLogSettings,
} from 'src/types'

const ACTION_LOG_INITIAL_STATE: ActionLog = {
  entries: [],
  totalEntries: 0,
  totalPages: 1,
}

const ACTION_LOG_SETTINGS_INITIAL_STATE__UNRESOLVED: ActionLogSettings = {
  isUnresolved: true,
  pagination: {
    currentPage: 1,
    perPage: 10,
  },
  selectedIds: [],
  sortModel: {
    sortBy: ActionLogSortBy.commentPosted,
    sortOrder: ActionLogSortOrder.desc,
  },
  resolvedTypeFilter: 'UNRESOLVED',
  abuseTypeFilter: 'ALL',
}

const ACTION_LOG_SETTINGS_INITIAL_STATE__RESOLVED: ActionLogSettings = {
  ...ACTION_LOG_SETTINGS_INITIAL_STATE__UNRESOLVED,
  isUnresolved: false,
  resolvedTypeFilter: 'ALL',
}

const ACTION_LOG_TOTALS_INITIAL_STATE = {
  unresolved: 0,
  resolved: 0,
}

export default function ActionLogPage() {
  // Retrieve the current URL query params
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const showUnresolvedTab = params.get('tab') === 'unresolved'

  // Retrieve filters from the Redux store
  const filters = useSelector((state: RootState) => state.filters)

  // State for action log data
  const [errorShown, setErrorShown] = useState(false)
  const [initialDataLoading, setInitialDataLoading] = useState(true)
  const [isUnresolvedTab, setIsUnresolvedTab] = useState(showUnresolvedTab)

  const [unresolvedActionLog, setUnresolvedActionLog] = useState<ActionLog>(
    ACTION_LOG_INITIAL_STATE
  )
  const [unresolvedActionLogSettings, setUnresolvedActionLogSettings] =
    useState<ActionLogSettings>(ACTION_LOG_SETTINGS_INITIAL_STATE__UNRESOLVED)
  const [resolvedActionLog, setResolvedActionLog] = useState<ActionLog>(
    ACTION_LOG_INITIAL_STATE
  )
  const [resolvedActionLogSettings, setResolvedActionLogSettings] =
    useState<ActionLogSettings>(ACTION_LOG_SETTINGS_INITIAL_STATE__RESOLVED)
  const [actionLogTotals, setActionLogTotals] = useState<ActionLogTotals>(
    ACTION_LOG_TOTALS_INITIAL_STATE
  )

  // Fetch action log data
  const { loading, error, fetch: fetchActionLog } = useFetchActionLog()
  const {
    loading: loadingTotals,
    error: errorTotals,
    fetch: fetchTotals,
  } = useFetchActionLogTotals()

  // Fetch action log data
  const fetchActionLogs = useCallback(
    async (props: FetchActionLogProps) => {
      if (props.isUnresolved) {
        const actionLog = await fetchActionLog(props)
        setUnresolvedActionLog(actionLog)
      } else {
        const actionLog = await fetchActionLog(props)
        setResolvedActionLog(actionLog)
      }
    },
    [fetchActionLog]
  )

  const fetchActionLogTotals = useCallback(async () => {
    const unresolvedTotal = await fetchTotals({
      isUnresolved: true,
      globalFilters: filters,
      abuseTypeFilter: unresolvedActionLogSettings.abuseTypeFilter,
      resolvedTypeFilter: unresolvedActionLogSettings.resolvedTypeFilter,
    })
    const resolvedTotal = await fetchTotals({
      isUnresolved: false,
      globalFilters: filters,
      abuseTypeFilter: resolvedActionLogSettings.abuseTypeFilter,
      resolvedTypeFilter: resolvedActionLogSettings.resolvedTypeFilter,
    })

    setActionLogTotals({
      unresolved: unresolvedTotal,
      resolved: resolvedTotal,
    })
  }, [
    fetchTotals,
    filters,
    unresolvedActionLogSettings.abuseTypeFilter,
    unresolvedActionLogSettings.resolvedTypeFilter,
    resolvedActionLogSettings.abuseTypeFilter,
    resolvedActionLogSettings.resolvedTypeFilter,
  ])

  const refetchData = useCallback(async () => {
    if (isUnresolvedTab)
      await fetchActionLogs({
        globalFilters: filters,
        isUnresolved: isUnresolvedTab,
        abuseTypeFilter: unresolvedActionLogSettings.abuseTypeFilter,
        pagination: unresolvedActionLogSettings.pagination,
        sortModel: unresolvedActionLogSettings.sortModel,
        resolvedTypeFilter: unresolvedActionLogSettings.resolvedTypeFilter,
      })
    else
      await fetchActionLogs({
        globalFilters: filters,
        isUnresolved: isUnresolvedTab,
        abuseTypeFilter: resolvedActionLogSettings.abuseTypeFilter,
        pagination: resolvedActionLogSettings.pagination,
        sortModel: resolvedActionLogSettings.sortModel,
        resolvedTypeFilter: resolvedActionLogSettings.resolvedTypeFilter,
      })

    await fetchActionLogTotals()

    setInitialDataLoading(false)
  }, [
    fetchActionLogs,
    fetchActionLogTotals,
    filters,
    isUnresolvedTab,
    unresolvedActionLogSettings.abuseTypeFilter,
    unresolvedActionLogSettings.pagination,
    unresolvedActionLogSettings.sortModel,
    unresolvedActionLogSettings.resolvedTypeFilter,
    resolvedActionLogSettings.abuseTypeFilter,
    resolvedActionLogSettings.pagination,
    resolvedActionLogSettings.sortModel,
    resolvedActionLogSettings.resolvedTypeFilter,
  ])

  useEffect(() => {
    refetchData()
  }, [refetchData])

  useEffect(() => {
    if ((error || errorTotals) && !errorShown) {
      setErrorShown(true)
      notifications.show({
        title: 'Uh-oh! Something Went Wrong',
        message:
          'We were unable to load your data. Please refresh to try again.',
        autoClose: 5000,
      })
    }
  }, [error, errorTotals, errorShown])

  if (initialDataLoading && loading) return <Loading />

  return (
    <Page noBorder>
      <Group position="apart">
        <Title order={1}>Action Log</Title>
        <ActionLogFilters
          loadingData={loading || loadingTotals}
          filters={filters}
        />
      </Group>
      <Space h={32} />
      <ActionLogTabs
        loadingData={loading}
        isUnresolvedTab={isUnresolvedTab}
        setIsUnresolvedTab={setIsUnresolvedTab}
        actionLogTotals={actionLogTotals}
        unresolvedActionLog={unresolvedActionLog}
        unresolvedActionLogSettings={unresolvedActionLogSettings}
        setUnresolvedActionLogSettings={setUnresolvedActionLogSettings}
        resolvedActionLog={resolvedActionLog}
        resolvedActionLogSettings={resolvedActionLogSettings}
        setResolvedActionLogSettings={setResolvedActionLogSettings}
        refetchActionLogData={refetchData}
      />
    </Page>
  )
}
