import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Grid, Image, Title, Group } from '@mantine/core'
import { GridPaginationModel } from '@mui/x-data-grid'

import {
  Page,
  CommentAuthorProfileInformationCard,
  MutedTable,
  Loading,
  ComboDatePicker,
} from 'src/components'
import {
  useFetchCommentAuthorComments,
  useFetchCommentAuthorsActioned,
} from 'src/api'
import { CommentAuthor } from 'src/types'
import { RootState, setDateRangeFilter } from 'src/redux/reducers'

const INITIAL_PAGINATION = {
  page: 0,
  pageSize: 25,
}

export default function MutedPage() {
  const filters = useSelector((state: RootState) => state.filters)

  const [expandedCommentAuthor, setExpandedCommentAuthor] =
    useState<CommentAuthor | null>(null)
  const [commentsPagination, setCommentsPagination] =
    useState<GridPaginationModel>(INITIAL_PAGINATION)
  const [refetchMutedCommenters, setRefetchMutedCommenters] = useState(false)

  const { data: commentAuthors, fetch: fetchCommentAuthors } =
    useFetchCommentAuthorsActioned()

  const {
    loading: loadingComments,
    data: comments,
    fetch: fetchComments,
  } = useFetchCommentAuthorComments()

  useEffect(() => {
    fetchCommentAuthors()
    // resetComments()
  }, [fetchCommentAuthors])

  useEffect(() => {
    fetchComments(expandedCommentAuthor, filters)
  }, [expandedCommentAuthor, filters, fetchComments])

  useEffect(() => {
    if (refetchMutedCommenters) {
      fetchCommentAuthors()
      setRefetchMutedCommenters(false)
    }
  }, [refetchMutedCommenters, fetchCommentAuthors])

  if (commentAuthors === null) return <Loading />

  return (
    <Page title="Muted Commenters">
      <Grid gutter="lg" grow>
        <Grid.Col span={12}>
          <MutedTable
            mutedCommenters={commentAuthors}
            setExpandedCommentAuthor={setExpandedCommentAuthor}
            refreshMutedCommenters={setRefetchMutedCommenters}
          />
        </Grid.Col>

        <header
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '20px',
            marginLeft: '10px',
            marginRight: '10px',
            width: '100%',
          }}
        >
          <Group>
            <Title order={2}>Profile Information</Title>
            <div style={{ height: '50px' }}>
              {loadingComments && (
                <Image
                  src="/flare/making-lemonade.gif"
                  alt="Empty cup of lemonade"
                  width={48}
                />
              )}
            </div>
          </Group>

          {expandedCommentAuthor && (
            <ComboDatePicker
              selectedDate={filters.dateRange}
              stateReducer={setDateRangeFilter}
            />
          )}
        </header>
        <CommentAuthorProfileInformationCard
          comments={comments}
          profileExpanded={true}
          commentAuthor={expandedCommentAuthor}
          pagination={commentsPagination}
          setPagination={setCommentsPagination}
          loading={loadingComments}
        />
      </Grid>
    </Page>
  )
}
