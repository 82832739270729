import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { modals } from '@mantine/modals'
import { Button, Text, Stack, Group } from '@mantine/core'

import { logout } from 'src/redux/reducers'
import { useSendDeactivateOrganization } from 'src/api'
import { ErrorMessage } from 'src/components'

export const DeactivateOrganizationModal = ({
  navigateDeactivatedPage,
}: {
  navigateDeactivatedPage: () => void
}) => {
  const dispatch = useDispatch()

  const { send, loading, error } = useSendDeactivateOrganization()

  const handleClick = useCallback(async () => {
    const success = await send()

    if (success) {
      modals.closeAll()
      dispatch(logout())
      navigateDeactivatedPage()
    }
  }, [send, dispatch, navigateDeactivatedPage])

  return (
    <Stack>
      <Text>Are you sure you want to deactivate your organization?</Text>
      <Text>
        Deactivating your organization will disconnect all connected social
        media accounts from Areto and disable all user accounts, including your
        own. This action is irreversible.
      </Text>

      <Group position="right">
        <Button variant="outline" onClick={() => modals.closeAll()}>
          Cancel
        </Button>
        <Button variant="error" loading={loading} onClick={() => handleClick()}>
          Yes, deactivate
        </Button>
      </Group>
      <ErrorMessage error={error} />
    </Stack>
  )
}
