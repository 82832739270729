import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams, Link } from 'react-router-dom'

import {
  Grid,
  Title,
  Image,
  Badge,
  Tooltip,
  Text,
  Switch,
  Group,
  Button,
  createStyles,
} from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { GridPaginationModel } from '@mui/x-data-grid'

import {
  Page,
  DashboardFilters,
  Loading,
  TrollFinderCard,
  FanFinderCard,
  CommentAuthorProfileInformationCard,
} from 'src/components'
import { RootState } from 'src/redux/reducers'
import {
  useFetchTrolls,
  useFetchCommentAuthorComments,
  useFetchFans,
} from 'src/api'
import { CommentAuthor } from 'src/types'

const useStyles = createStyles(theme => ({
  track: {
    // off color / troll finder color
    backgroundColor: theme.colors.primary[6],
    borderColor: theme.colors.primary[6],
  },
}))

const INITIAL_PAGINATION = {
  page: 0,
  pageSize: 25,
}

export default function TrollFanPage() {
  const { classes } = useStyles()

  // Get the 'tab' query parameter or default to 'trolls' if not present
  const [searchParams] = useSearchParams()
  const initialTab = searchParams.get('tab') || 'trolls'

  const [activeTab, setActiveTab] = useState(initialTab)
  const [expandedCommentAuthor, setExpandedCommentAuthor] =
    useState<CommentAuthor | null>(null)
  const [profileExpanded, setProfileExpanded] = useState(false)
  const [errorShown, setErrorShown] = useState(false)

  const [commentsPagination, setCommentsPagination] =
    useState<GridPaginationModel>(INITIAL_PAGINATION)

  const filters = useSelector((state: RootState) => state.filters)

  const {
    error: errorTrolls,
    loading: loadingTrolls,
    data: trolls,
    fetch: fetchTrolls,
  } = useFetchTrolls()

  const {
    error: errorFans,
    loading: loadingFans,
    data: fans,
    fetch: fetchFans,
  } = useFetchFans()

  // Fetch the comments for the expanded comment author
  const {
    loading: loadingComments,
    data: comments,
    error: errorComments,
    reset: resetComments,
    fetch: fetchComments,
  } = useFetchCommentAuthorComments()

  useEffect(() => {
    if (!errorShown && (errorTrolls || errorComments || errorFans)) {
      setErrorShown(true)
      notifications.show({
        title: 'Uh-oh! Something Went Wrong',
        message:
          'We were unable to load your data. Please refresh to try again.',
        autoClose: 5000,
      })
    }
  }, [errorShown, errorTrolls, errorFans, errorComments])

  useEffect(() => {
    // Reset the expandedId and relatedComments when the activeTab changes
    setExpandedCommentAuthor(null)
    setProfileExpanded(false)
    setCommentsPagination(INITIAL_PAGINATION)
    resetComments()
  }, [activeTab, filters, resetComments])

  useEffect(() => {
    if (activeTab === 'trolls') {
      fetchTrolls(filters, 5)
    } else {
      fetchFans(filters, 5)
    }
  }, [activeTab, filters, fetchTrolls, fetchFans])

  useEffect(() => {
    if (expandedCommentAuthor) {
      fetchComments(expandedCommentAuthor, filters)
    }
  }, [expandedCommentAuthor, filters, fetchComments])

  if (trolls === null && fans === null) return <Loading />

  return (
    <Page
      title="Troll & Fan Profiles"
      titleChildren={
        <Tooltip
          multiline
          label="The Troll & Fan Finder feature is a Beta version. Currently, only the top 5 trolls or fans are displayed at a time, as well as a max of 100 comments - stay tuned for more in future updates!"
        >
          <Badge
            style={{ marginLeft: '10px' }}
            color="pink"
            size="lg"
            radius="xs"
          >
            BETA
          </Badge>
        </Tooltip>
      }
      right={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Text size="md">Troll Finder</Text>
            <Switch
              checked={activeTab === 'fans'}
              onChange={event =>
                setActiveTab(event.currentTarget.checked ? 'fans' : 'trolls')
              }
              classNames={{ track: classes.track }}
            />
            <Text size="md">Fan Finder</Text>
          </div>

          <DashboardFilters
            filters={filters}
            loadingData={loadingTrolls || loadingFans}
          />
        </div>
      }
    >
      <Grid gutter="lg" grow>
        <Grid.Col span={12}>
          {activeTab === 'trolls' && trolls !== null ? (
            <TrollFinderCard
              trolls={trolls}
              expanded={true}
              setProfileExpanded={setProfileExpanded}
              setExpandedCommentAuthor={setExpandedCommentAuthor}
            />
          ) : (
            <FanFinderCard
              fans={fans !== null ? fans : []}
              expanded={true}
              setProfileExpanded={setProfileExpanded}
              setExpandedCommentAuthor={setExpandedCommentAuthor}
            />
          )}
        </Grid.Col>

        <header
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '20px',
            marginLeft: '10px',
            marginRight: '10px',
            width: '100%',
          }}
        >
          <Group>
            <Title order={2}>Profile Information</Title>
            <div style={{ height: '50px' }}>
              {loadingComments && (
                <Image
                  src="/flare/making-lemonade.gif"
                  alt="Empty cup of lemonade"
                  width={48}
                />
              )}
            </div>
          </Group>

          {activeTab === 'trolls' && (
            <Button
              variant="outline"
              size="md"
              type="submit"
              to="/muted-commenters"
              component={Link}
            >
              Manage Muted Commenters
            </Button>
          )}
        </header>
        <CommentAuthorProfileInformationCard
          comments={comments}
          profileExpanded={profileExpanded}
          commentAuthor={expandedCommentAuthor}
          pagination={commentsPagination}
          setPagination={setCommentsPagination}
          loading={loadingComments}
        />
      </Grid>
    </Page>
  )
}
