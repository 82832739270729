import { Card } from '@mantine/core'
import { DataGrid } from '@mui/x-data-grid'

import { Empty } from 'src/components'
import { CommentAuthor } from 'src/types'

import { createMutedColumns } from './MutedColumns'

interface MutedTableProps {
  mutedCommenters: CommentAuthor[]
  setExpandedCommentAuthor?: (commentAuthor: CommentAuthor) => void
  refreshMutedCommenters: (refresh: boolean) => void
}

export const MutedTable = (props: MutedTableProps) => {
  const handleRowClick = (params: any) => {
    if (!props.setExpandedCommentAuthor) return
    props.setExpandedCommentAuthor(params.row as CommentAuthor)
  }

  return (
    <Card shadow="xl" radius="lg" p="xl">
      {(!props.mutedCommenters || props.mutedCommenters.length === 0) && (
        <Empty
          title="Looks like you haven't muted any commenters!"
          message={
            <>
              Muting problematic commenters ensures all of their future comments
              are hidden from your audience. Commenters can be muted from the
              Troll Profiles or Action Log.
            </>
          }
        />
      )}

      {props.mutedCommenters && props.mutedCommenters.length > 0 && (
        <DataGrid
          autoHeight
          sx={{
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'default',
              cursor: 'pointer',
            },
          }}
          style={{
            border: 0,
            boxShadow: 'none',
            padding: 0,
          }}
          rows={props.mutedCommenters}
          columns={createMutedColumns(props.refreshMutedCommenters)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          getRowId={row =>
            `${row.platform}-${row.platformId}-${row.trackedUser.id}`
          }
          onRowClick={handleRowClick}
        />
      )}
    </Card>
  )
}
