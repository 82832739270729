import { useState, useCallback } from 'react'

import client from '../client'
import { MutedCommentAuthorResponse, Response, CommentAuthor } from 'src/types'

export const useFetchCommentAuthorsActioned = () => {
  const [data, setData] = useState<CommentAuthor[] | null>(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(async () => {
    setLoading(true)

    try {
      const response = await client.get(
        '/platforms/users/comment-authors/action'
      )
      const data = response.data as Response<MutedCommentAuthorResponse>
      setData(data.response.commentAuthors)
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  return { data, error, loading, fetch }
}
