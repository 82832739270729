import { Grid } from '@mantine/core'

import { ModerationPreview as mp } from 'src/types'
import { ModerationPreviewCard } from './ModerationPreviewCard'

export const ModerationPreview = (props: mp) => {
  // Hide if there is no data
  if (props!.toActionTotal === 0 && props!.moderatedTotal === 0)
    return <div></div>

  return (
    <section className="page-section">
      <Grid>
        {props!.toActionTotal > 0 && (
          <Grid.Col span={6}>
            <ModerationPreviewCard
              title="TO ACTION"
              numModerationQueueEntries={props!.toActionTotal}
              abuseTags={props!.toActionAbuseTags}
              isUnresolved={true}
              button={{
                title: 'Take Action Now',
                href: '/action-log?tab=unresolved',
                variant: 'filled',
              }}
            />
          </Grid.Col>
        )}
        {props!.moderatedTotal > 0 && (
          <Grid.Col span={6}>
            <ModerationPreviewCard
              title="MODERATED"
              numModerationQueueEntries={props!.moderatedTotal}
              abuseTags={props!.moderatedAbuseTags}
              isUnresolved={false}
              button={{
                title: 'See Action Log',
                href: '/action-log?tab=resolved',
                variant: 'outline',
              }}
            />
          </Grid.Col>
        )}
      </Grid>
    </section>
  )
}
