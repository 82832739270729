import { useState, useCallback } from 'react'

import { Button, Group, Select, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { notifications } from '@mantine/notifications'

import { ExpiryInterval, ExpiryIntervalLabels, ActionType } from 'src/types'
import { useSendCommentAuthorAction } from 'src/api'

interface MuteModalProps {
  trackedUserId: number
  commentAuthor: string
  commentAuthorId: string
  successCallback?: () => void
  isExtend?: boolean
}

export const MuteModal = (props: MuteModalProps) => {
  const [duration, setDuration] = useState<ExpiryInterval>(
    ExpiryInterval.ONE_HOUR
  )

  const { loading, send } = useSendCommentAuthorAction()

  const sendAction = useCallback(async () => {
    const success = await send(
      props.trackedUserId,
      props.commentAuthor,
      props.commentAuthorId,
      ActionType.MUTE,
      duration
    )

    modals.closeAll()

    if (success) {
      if (props.successCallback) props.successCallback()

      notifications.show({
        title: 'Success!',
        message: props.isExtend
          ? `The mute for ${props.commentAuthor} has been updated to ${ExpiryIntervalLabels[duration!].toLowerCase()}.`
          : `${props.commentAuthor} has been muted for ${ExpiryIntervalLabels[duration!].toLowerCase()}.`,
        color: 'teal',
      })
    } else {
      notifications.show({
        title: 'Uh-oh! Something Went Wrong',
        message: props.isExtend
          ? 'We were unable to extend the mute for this commenter. Please try again later.'
          : 'We were unable to mute this commenter. Please try again later.',
      })
    }
  }, [send, props, duration])

  return (
    <>
      {props.isExtend ? (
        <Text color="white">
          Would you like to update the expiry for the following muted commenter:{' '}
          <b>{props.commentAuthor}</b>? All new comments by muted commenters are
          hidden, unless they are abusive or spam. In those cases, they will be
          moderated as usual. When the mute expires, the user's comments will be
          visible from that time onward.
        </Text>
      ) : (
        <Text color="white">
          Would you like to mute <b>{props.commentAuthor}</b>? All new comments
          by muted commenters are hidden, unless they are abusive or spam. In
          those cases, they will be moderated as usual. When the mute expires,
          the user's comments will be visible from that time onward. If the user
          is already muted, the mute duration will be updated.
        </Text>
      )}
      <Select
        data={Object.entries(ExpiryIntervalLabels).map(([key, value]) => ({
          label: value,
          value: key,
        }))}
        value={duration}
        onChange={value =>
          setDuration(ExpiryInterval[value as keyof typeof ExpiryInterval])
        }
        withinPortal
        mt={16}
      />
      <Group position="right" mt={16}>
        <Button
          variant="outline"
          disabled={loading}
          onClick={() => modals.closeAll()}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          loading={loading}
          loaderPosition="center"
          onClick={sendAction}
        >
          Mute
        </Button>
      </Group>
    </>
  )
}
