import { Group, Image } from '@mantine/core'

import { useDispatch } from 'react-redux'

import { ComboDatePicker, TrackedUserMultiSelect } from 'src/components'
import {
  setTrackedAccountsFilter,
  setDateRangeFilter,
} from 'src/redux/reducers'
import { Filters } from 'src/types'

export interface DashboardFiltersProps {
  loadingData: boolean
  filters: Filters
}

export const DashboardFilters = (props: DashboardFiltersProps) => {
  const dispatch = useDispatch()

  return (
    <Group>
      {props.loadingData ? (
        <Image
          src="/flare/making-lemonade.gif"
          alt="Empty cup of lemonade"
          width={48}
        />
      ) : (
        <div></div>
      )}
      <TrackedUserMultiSelect
        onChange={v => dispatch(setTrackedAccountsFilter(v))}
        initialValues={props.filters.trackedAccounts}
      />
      <ComboDatePicker
        selectedDate={props.filters.dateRange}
        stateReducer={setDateRangeFilter}
      />
    </Group>
  )
}
