export enum ActionLogSortBy {
  commentPosted = 'COMMENT_TIMESTAMP',
  commentAuthor = 'COMMENT_AUTHOR',
}

export enum ActionLogSortOrder {
  asc = 'ASC',
  desc = 'DESC',
}

export interface ActionLogSortModel {
  sortBy: ActionLogSortBy
  sortOrder: ActionLogSortOrder
}
