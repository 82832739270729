import { createStyles, Navbar, Stack, Image, Space } from '@mantine/core'
import { useSelector } from 'react-redux'

import { NavbarLink, AccountButton, Anchor } from 'src/components'
import { RootState } from 'src/redux/rootReducer'

const useStyles = createStyles(theme => ({
  navBar: {
    width: 64,
    backgroundColor: theme.colors.dark[5],
    borderRight: 'none',
    borderTopRightRadius: 32,
    borderBottomRightRadius: 32,
  },
}))

export function NavBar() {
  const profile = useSelector((state: RootState) => state.user.profile)

  const { classes } = useStyles()

  return (
    <Navbar className={classes.navBar}>
      <Stack align="center" spacing={20} style={{ height: '100%' }}>
        <Anchor to="/">
          <Image src="/logo/logo.svg" alt="logo" width={32} mt={72} />
        </Anchor>
        <Space h={12} />
        <NavbarLink label="Brand Summary" to="/" icon="bxs:bar-chart-alt-2" />
        <NavbarLink
          label="Action Log"
          to="/action-log?tab=unresolved"
          icon="bxs:comment-check"
        />
        <NavbarLink
          label="Troll & Fan Profiles"
          to="/troll-fan-profiles"
          icon="bxs:user-detail"
        />
        <NavbarLink
          label="Muted Commenters"
          to="/muted-commenters"
          icon="mdi:account-off-outline"
        />
        {profile && (
          <NavbarLink label="Settings" to="/settings" icon="mdi:settings" />
        )}
        {profile && (
          <AccountButton profile={profile} style={{ marginTop: 'auto' }} />
        )}
        <Space h={56} />
      </Stack>
    </Navbar>
  )
}
