import { NoTrackedUsers } from './NoTrackedUsers'
import { AccountsTable } from './AccountsTable'
import { TrackedUsersStatsResponse } from 'src/types'

export const Accounts = (props: TrackedUsersStatsResponse) => {
  return (
    <>
      {props && !props.trackedUsers.current?.length ? (
        <NoTrackedUsers />
      ) : (
        <AccountsTable accounts={props!} />
      )}
    </>
  )
}
