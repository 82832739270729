import {
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  GridRowParams,
  GridActionsCellItem,
} from '@mui/x-data-grid'
import { modals } from '@mantine/modals'
import { Icon } from '@iconify/react'

import {
  PlatformUsername,
  AbuseTags,
  CommentModal,
  ActionModal,
  MuteModal,
} from 'src/components'
import {
  TrackedUser,
  ModerationResolveTypeLabels,
  ActionLogEntry,
  ModerationResolveType,
  ModerationActionType,
  ActionLogSettings,
} from 'src/types'
import { parseToLocalDateTime } from 'src/utils/date'
import { createCommenterProfileLink } from 'src/utils/socials'

const openActionModal = (
  title: string,
  action: ModerationActionType,
  singleId: number,
  settings: ActionLogSettings,
  updateSettings: (settings: ActionLogSettings) => void,
  refetchActionLogData: () => void
) => {
  modals.open({
    title: title,
    centered: true,
    children: (
      <ActionModal
        action={action}
        singleId={singleId}
        settings={settings}
        updateSettings={updateSettings}
        refetchActionLogData={refetchActionLogData}
      />
    ),
    size: 'md',
  })
}

const openCommentModal = (entry: ActionLogEntry) => {
  modals.open({
    title: 'Comment Details',
    centered: true,
    children: <CommentModal entry={entry} />,
    size: 'lg',
  })
}

interface ActionLogTableColumnsProps {
  settings: ActionLogSettings
  updateSettings: (settings: ActionLogSettings) => void
  refetchActionLogData: () => void
}

const TRACKED_USER_COLUMN = {
  field: 'trackedUser',
  headerName: 'To'.toLocaleUpperCase(),
  flex: 1,
  sortable: false,
  renderCell: (params: GridRenderCellParams<TrackedUser>) => (
    <PlatformUsername
      platform={params.value.platform}
      username={params.value.platformUsername}
    />
  ),
}

const COMMENT_AUTHOR_COLUMN = {
  field: 'commentAuthor',
  headerName: 'From'.toLocaleUpperCase(),
  flex: 1,
}

const COMMENT_COLUMN = {
  field: 'comment',
  headerName: 'Comment'.toLocaleUpperCase(),
  flex: 3,
  sortable: false,
}

const ABUSE_TAGS_COLUMN = {
  field: 'abuseTags',
  headerName: 'Abuse Types'.toLocaleUpperCase(),
  flex: 1.25,
  sortable: false,
  renderCell: (params: GridRenderCellParams<string[]>) => {
    if (params.value) return <AbuseTags tags={params.value} numToShow={1} />
    return <div></div>
  },
}

const COMMENT_POSTED_COLUMN = {
  field: 'commentPosted',
  headerName: 'Posted'.toLocaleUpperCase(),
  flex: 1.2,
  valueGetter: (params: GridValueGetterParams) =>
    parseToLocalDateTime(params.row.commentPosted),
}

const RESOLVED_STATUS_COLUMN = {
  field: 'resolutionStatus',
  headerName: 'Status'.toLocaleUpperCase(),
  flex: 0.75,
  sortable: false,
  valueGetter: (params: GridValueGetterParams) =>
    `${ModerationResolveTypeLabels[params.value?.status]}`,
}

const createUnresolvedActionColumn = (props: ActionLogTableColumnsProps) => {
  return {
    field: 'actions',
    type: 'actions',
    width: 60,
    getActions: (params: GridRowParams) => {
      const rowEntry: ActionLogEntry = params.row.entry as ActionLogEntry
      let actionItems = [
        <GridActionsCellItem
          icon={<Icon icon="mdi:arrow-expand" />}
          onClick={() => openCommentModal(rowEntry)}
          label="Expand"
        />,
        <GridActionsCellItem
          icon={<Icon icon="mdi:eye-off" />}
          onClick={() =>
            openActionModal(
              'Confirm: Hide',
              ModerationActionType.HIDE,
              rowEntry.id,
              props.settings,
              props.updateSettings,
              props.refetchActionLogData
            )
          }
          label="Hide"
          showInMenu
        />,
      ]

      if (rowEntry.trackedUser.platform !== 'TIKTOK')
        actionItems.push(
          <GridActionsCellItem
            icon={<Icon icon="mdi:delete" />}
            onClick={() =>
              openActionModal(
                'Confirm: Delete',
                ModerationActionType.DELETE,
                rowEntry.id,
                props.settings,
                props.updateSettings,
                props.refetchActionLogData
              )
            }
            label="Delete"
            showInMenu
          />
        )

      actionItems.push(
        <GridActionsCellItem
          icon={<Icon icon="mdi:shield-off" />}
          onClick={() =>
            openActionModal(
              'Confirm: Not Abusive',
              ModerationActionType.FALSE_POSITIVE,
              rowEntry.id,
              props.settings,
              props.updateSettings,
              props.refetchActionLogData
            )
          }
          label="Not Abusive"
          showInMenu
        />,
        <GridActionsCellItem
          icon={<Icon icon="mdi:archive" />}
          onClick={() =>
            openActionModal(
              'Confirm: Archive',
              ModerationActionType.ARCHIVE,
              rowEntry.id,
              props.settings,
              props.updateSettings,
              props.refetchActionLogData
            )
          }
          label="Archive"
          showInMenu
        />
      )

      if (rowEntry.comment)
        actionItems.push(
          <GridActionsCellItem
            icon={<Icon icon="material-symbols:content-copy" />}
            onClick={() => navigator.clipboard.writeText(rowEntry.comment.text)}
            label="Copy Comment"
            showInMenu
          />
        )

      if (rowEntry.comment && rowEntry.comment.sourceUrl)
        actionItems.push(
          <GridActionsCellItem
            icon={<Icon icon="mdi:open-in-new" />}
            onClick={() => window.open(rowEntry.comment.sourceUrl)}
            label="View Original"
            showInMenu
          />
        )

      if (rowEntry.comment) {
        const platformURL = createCommenterProfileLink(
          rowEntry.trackedUser.platform,
          rowEntry.comment.author
        )

        if (platformURL !== '') {
          actionItems.push(
            <GridActionsCellItem
              icon={<Icon icon="mdi:identification-card-outline" />}
              onClick={() => window.open(platformURL)}
              label="View Profile"
              showInMenu
            />
          )
        }
      }

      if (rowEntry.comment) {
        actionItems.push(
          <GridActionsCellItem
            icon={<Icon icon="mdi:account-off-outline" />}
            onClick={() =>
              modals.open({
                title: 'Mute Commenter',
                centered: true,
                children: (
                  <MuteModal
                    trackedUserId={rowEntry.trackedUser.id}
                    commentAuthor={rowEntry.comment.author}
                    commentAuthorId={rowEntry.comment.authorId}
                  />
                ),
                size: 'md',
              })
            }
            label="Mute Commenter"
            showInMenu
          />
        )
      }

      return actionItems
    },
  }
}

const createResolvedActionsColumn = (props: ActionLogTableColumnsProps) => {
  return {
    field: 'actions',
    type: 'actions',
    width: 60,
    getActions: (params: GridRowParams) => {
      const rowEntry: ActionLogEntry = params.row.entry as ActionLogEntry
      let actionItems = [
        <GridActionsCellItem
          icon={<Icon icon="mdi:arrow-expand" />}
          onClick={() => openCommentModal(rowEntry)}
          label="Expand"
        />,
      ]

      if (rowEntry.resolution.status === ModerationResolveType.HIDDEN) {
        actionItems.push(
          <GridActionsCellItem
            icon={<Icon icon="mdi:eye" />}
            onClick={() =>
              openActionModal(
                'Confirm: Undo Hide',
                ModerationActionType.UNDO_HIDE,
                rowEntry.id,
                props.settings,
                props.updateSettings,
                props.refetchActionLogData
              )
            }
            label="Undo Hide"
            showInMenu
          />
        )

        if (rowEntry.trackedUser.platform !== 'TIKTOK') {
          actionItems.push(
            <GridActionsCellItem
              icon={<Icon icon="mdi:delete" />}
              onClick={() =>
                openActionModal(
                  'Confirm: Delete',
                  ModerationActionType.DELETE,
                  rowEntry.id,
                  props.settings,
                  props.updateSettings,
                  props.refetchActionLogData
                )
              }
              label="Delete"
              showInMenu
            />
          )
        }
      }

      if (rowEntry.resolution.status === ModerationResolveType.ARCHIVED)
        actionItems.push(
          <GridActionsCellItem
            icon={<Icon icon="mdi:archive-remove" />}
            onClick={() =>
              openActionModal(
                'Confirm: Unarchive',
                ModerationActionType.UNARCHIVE,
                rowEntry.id,
                props.settings,
                props.updateSettings,
                props.refetchActionLogData
              )
            }
            label="Unarchive"
            showInMenu
          />
        )

      if (rowEntry.comment) {
        actionItems.push(
          <GridActionsCellItem
            icon={<Icon icon="mdi:account-off-outline" />}
            onClick={() =>
              modals.open({
                title: 'Mute Commenter',
                centered: true,
                children: (
                  <MuteModal
                    trackedUserId={rowEntry.trackedUser.id}
                    commentAuthor={rowEntry.comment.author}
                    commentAuthorId={rowEntry.comment.authorId}
                  />
                ),
                size: 'md',
              })
            }
            label="Mute Commenter"
            showInMenu
          />
        )
      }

      if (rowEntry.comment)
        actionItems.push(
          <GridActionsCellItem
            icon={<Icon icon="material-symbols:content-copy" />}
            onClick={() => navigator.clipboard.writeText(rowEntry.comment.text)}
            label="Copy Comment"
            showInMenu
          />
        )

      if (rowEntry.comment && rowEntry.comment.sourceUrl)
        actionItems.push(
          <GridActionsCellItem
            icon={<Icon icon="mdi:open-in-new" />}
            onClick={() => window.open(rowEntry.comment.sourceUrl)}
            label="View Original"
            showInMenu
          />
        )

      if (rowEntry.comment) {
        const platformURL = createCommenterProfileLink(
          rowEntry.trackedUser.platform,
          rowEntry.comment.author
        )

        if (platformURL !== '') {
          actionItems.push(
            <GridActionsCellItem
              icon={<Icon icon="mdi:identification-card-outline" />}
              onClick={() => window.open(platformURL)}
              label="View Profile"
              showInMenu
            />
          )
        }
      }

      return actionItems
    },
  }
}

export const createColumnData = (props: ActionLogTableColumnsProps) => {
  if (props.settings.isUnresolved)
    return [
      TRACKED_USER_COLUMN,
      COMMENT_AUTHOR_COLUMN,
      COMMENT_COLUMN,
      ABUSE_TAGS_COLUMN,
      COMMENT_POSTED_COLUMN,
      createUnresolvedActionColumn(props),
    ] as GridColDef[]

  return [
    TRACKED_USER_COLUMN,
    COMMENT_AUTHOR_COLUMN,
    COMMENT_COLUMN,
    ABUSE_TAGS_COLUMN,
    COMMENT_POSTED_COLUMN,
    RESOLVED_STATUS_COLUMN,
    createResolvedActionsColumn(props),
  ] as GridColDef[]
}
