import { NoTrackedPlatforms } from './NoTrackedPlatforms'
import { PlatformsTable } from './PlatformsTable'
import { TrackedPlatformsStatsResponse } from '~/types'

export const Platforms = (props: TrackedPlatformsStatsResponse) => {
  return (
    <>
      {props && !props.platforms.current?.length ? (
        <NoTrackedPlatforms />
      ) : (
        <PlatformsTable platforms={props!} />
      )}
    </>
  )
}
