import { useState, useCallback } from 'react'
import {
  Response,
  TrackedPlatformsStatsResponse,
  DateRange,
  Filters,
} from 'src/types'

import client from '../client'

const createParams = (currentFilters: {
  dateRange: DateRange
  trackedAccounts: number[]
}): any => {
  return {
    start: currentFilters.dateRange.start,
    end: currentFilters.dateRange.end,
  }
}

export const useFetchPlatformStats = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<TrackedPlatformsStatsResponse | null>(null)

  const fetch = useCallback(async (props: Filters) => {
    setLoading(true)

    try {
      const response = await client.get('/stats/platforms', {
        params: createParams(props),
      })

      const data = response.data as Response<TrackedPlatformsStatsResponse>

      setData(data.response)
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  return { error, loading, data, fetch }
}
